import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../img/logo-principal.png";
import { Fade } from "react-awesome-reveal";
import { Container, Col, Row } from "react-bootstrap";
import "./Landing.css";
import { useNavigate } from "react-router-dom";
import Dropdown from "../components/Dropdown";
import LandingImg from "../img/landing-back.png";

const Landing = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [value, setValue] = useState("");
  const [error, setError] = useState("");
  const [chainData, setChainData] = useState([]);
  const [chainId, setChainId] = useState("Ethereum Mainnet");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const handleOnClick = () => navigate(`/search/${address}/${chainId}`);

  async function getChain() {
    const url =
      "https://27zbfe9ts0.execute-api.us-west-2.amazonaws.com/Test/chains";
    axios
      .get(url)
      .then((response) => {
        console.log(response.data.body)
        setChainData(response.data.body);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const handleSelectChain = (selectedChain) => {
    console.log("selected", selectedChain);
    setChainId(selectedChain);
  };

  const handleChange = (e) => {
    const input = e.target.value.trim();
    setValue(input);
    // Llamar a la función validarDireccion con el valor del input
    let patron = /^(0x)?[0-9a-fA-F]{40}$/;
    const isValidAddress = patron.test(input);
    setButtonDisabled(!isValidAddress);
    // Si es válido, borrar el error y pasar el valor al callback setAddress
    if (isValidAddress) {
      setError("");
      setAddress(input);
    } else {
      // Si no es válido, mostrar un mensaje de error y pasar una cadena vacía al callback setAddress
      setError("The address is not valid,  please check it and try again");
      setAddress("");
    }
  };

  useEffect(() => {
    getChain();
  }, []);

  return (
    <div className="background">
      <Col  sm={12} md={8} className="col-text-landing">
        <div className="container-text">
          <div className="header">
            <Fade delay="20" triggerOnce>
              <h1 className="first-title">
                ERC-6551
              </h1>
              <h1 className="second-title">
                Visualizer
              </h1>
              
            </Fade>
            <Fade delay="50" triggerOnce>
              <p className="d-none d-md-block">
                Explore the most interesting ERC-6551 
                <br/>
                token trees in the
                Blockchain world.
              </p>
              <p
                
                className="d-sm-block d-md-none"
              >
                Explore the most interesting ERC-6551 token trees in the
                Blockchain world.
              </p>
            </Fade>
          </div>

          <div className="search">
            <Row>
              <Col
                xs={12}
                className="col-dropdown"
                
              >
                <Dropdown data={chainData} func={handleSelectChain} />
                <div className="div-input-btn" style={{marginTop: '1rem'}}>
                  <input
                    type="text"
                    placeholder="Search by Address"
                    className="search-bar d-none d-md-block"
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    placeholder="Search by Address"
                    className="search-bar-mini d-sm-block d-md-none"
                    onChange={handleChange}
                  />


                  <button
                    className="landing-btn d-none d-md-block"
                    onClick={handleOnClick}
                    disabled={buttonDisabled}
                    >
                    Search
                  </button>
                  <button
                    className="landing-btn-mini d-sm-block d-md-none"
                    onClick={handleOnClick}
                    disabled={buttonDisabled}
                    >
                    Search
                  </button>
                </div>
                    {error && <p style={{ color: "red" }}>{error}</p>}
              </Col>
            </Row>
          </div>
          <div className="img-mobile-container d-sm-block d-md-none">
            <img src={LandingImg} alt='landing image' className="img-mobile" />
          </div>
          <Fade delay="80" triggerOnce>
            <div className="footer">
              <a
                href="https://dappsfactory.io/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Logo} alt="Logo" />
              </a>
             
            </div>
          </Fade>
        </div>
      </Col>
      <Col sm={12} md={4} className="img-landing-container ">
        <img src={LandingImg} alt="" className="img-landing d-none d-md-block" />
      </Col>
    </div>
  );
};

export default Landing;
