import React, { useState } from "react";
import {useParams} from 'react-router-dom'

import "./Dropdown.css";

const Dropdown = ({data, func}) => {
  const { chainId } = useParams();
  const [selected, setSelected] = useState(chainId);

  const handleChange = (event) => {
    const selectedChain = event.target.value;
    setSelected(selectedChain);
    func(selectedChain); 
  }
  return (
    <div className="dropdown">
        <select value={selected} onChange={handleChange}>
          {
            data?.sort((a, b) => a.order - b.order).map((item, index) => (
              <option key={index} value={item.chain}>{item.chain}</option>
              ))}
        </select>
    </div>
  );
};

// Exportar el componente Dropdown
export default Dropdown;
